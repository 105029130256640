// 3rd party imports
import * as React from 'react';

// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { useWindowSize } from 'react-use';

function Products() {
  const linksImgSrc = '../images/home/UI_Links.png';
  const searchWidgetImgSrc = '../images/home/UI_SearchWidget.png';
  const bannersImgSrc = '../images/home/UI_Banners.png';
  const creatorToolboxSrc = '../images/home/UI_CreatorToolbox.jpg';
  const performanceSrc = '../images/home/UI_Performance.png';
  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <div className="Products container">
      <UitkSpacing padding={{ blockend: 'six' }}>
        <UitkHeading tag="h2" size={3}>
          <LocalizedText message="home.products.heading.all_tools_you_need" />
        </UitkHeading>
      </UitkSpacing>
      <UitkSpacing padding={{ block: 'six' }}>
        <UitkLayoutGrid columns={{ medium: ['1fr', '1fr'], small: ['1fr'] }} space="twelve" alignItems="center">
          <UitkLayoutGridItem>
            <UitkFigure imageFit="contain" className="Products__image">
              <UitkImage alt="Creator Toolbox" src={creatorToolboxSrc} />
            </UitkFigure>
          </UitkLayoutGridItem>
          <UitkLayoutGridItem>
            <div>
              <UitkHeading size={4} tag="h2">
                <LocalizedText message="product.creator-toolbox.title" />
              </UitkHeading>
              <UitkText spacing="two">
                <LocalizedText message="home.products.creator-toolbox" />
              </UitkText>
            </div>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkSpacing>
      <UitkSpacing padding={{ block: 'twelve' }}>
        <UitkLayoutGrid
          areas={isMobile ? ['thumbnail', 'text'] : ['text thumbnail']}
          columns={{ medium: ['1fr', '1fr'], small: ['1fr'] }}
          space="twelve"
          alignItems="center"
        >
          <UitkLayoutGridItem area="text">
            <div>
              <UitkHeading size={4} tag="h2">
                <LocalizedText message="performance-charts.page.title" />
              </UitkHeading>
              <UitkText spacing="two">
                <LocalizedText message="home.products.performance" />
              </UitkText>
            </div>
          </UitkLayoutGridItem>
          <UitkLayoutGridItem area="thumbnail">
            <UitkFigure imageFit="contain" className="Products__image">
              <UitkImage alt="Performance" src={performanceSrc} />
            </UitkFigure>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkSpacing>
      <UitkSpacing padding={{ block: 'six' }}>
        <UitkLayoutGrid columns={{ medium: ['1fr', '1fr', '1fr'], small: ['1fr'] }} space="twelve" alignItems="center">
          <UitkLayoutGrid rows={{ medium: ['2fr', '1fr'], small: ['1fr'] }} space="twelve" alignItems="center">
            <UitkLayoutGridItem>
              <UitkFigure imageFit="contain" className="Products__image">
                <UitkImage alt="Links preview" src={linksImgSrc} />
              </UitkFigure>
            </UitkLayoutGridItem>
            <UitkLayoutGridItem>
              <div>
                <UitkHeading size={4} tag="h2">
                  <LocalizedText message="products.links.title" />
                </UitkHeading>
                <UitkText spacing="two">
                  <LocalizedText message="home.products.deeplinks.description" />
                </UitkText>
              </div>
            </UitkLayoutGridItem>
          </UitkLayoutGrid>
          <UitkLayoutGrid rows={{ medium: ['2fr', '1fr'], small: ['1fr'] }} space="twelve" alignItems="center">
            <UitkLayoutGridItem>
              <UitkFigure imageFit="contain" className="Products__image">
                <UitkImage alt="Search Widget preview" src={searchWidgetImgSrc} />
              </UitkFigure>
            </UitkLayoutGridItem>
            <UitkLayoutGridItem>
              <div>
                <UitkHeading size={4} tag="h2">
                  <LocalizedText message="products.search.title" />
                </UitkHeading>
                <UitkText spacing="two">
                  <LocalizedText message="home.products.search-widget.description" />
                </UitkText>
              </div>
            </UitkLayoutGridItem>
          </UitkLayoutGrid>
          <UitkLayoutGrid rows={{ medium: ['2fr', '1fr'], small: ['1fr'] }} space="twelve" alignItems="center">
            <UitkLayoutGridItem>
              <UitkFigure imageFit="contain" className="Products__image">
                <UitkImage alt="Banners preview" src={bannersImgSrc} />
              </UitkFigure>
            </UitkLayoutGridItem>
            <UitkLayoutGridItem>
              <div>
                <UitkHeading size={4} tag="h2">
                  <LocalizedText message="products.banners.title" />
                </UitkHeading>
                <UitkText spacing="two">
                  <LocalizedText message="home.products.banners.description" />
                </UitkText>
              </div>
            </UitkLayoutGridItem>
          </UitkLayoutGrid>
        </UitkLayoutGrid>
      </UitkSpacing>
    </div>
  );
}

export { Products };
