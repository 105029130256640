// 3rd party imports
import * as React from 'react';

// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkIcon } from 'uitk-react-icons';

function Benefits() {
  return (
    <UitkLayoutFlex className="Benefits container" space="twelve">
      <UitkLayoutFlexItem>
        <UitkFigure roundcorner={true}>
          <UitkImage alt="Affiliate hub benefits" src="../images/home/pose.jpg" />
        </UitkFigure>
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <UitkLayoutFlex direction="column" space="six">
          <UitkHeading tag="h2" size={3}>
            <LocalizedText message="home.benefits.heading.why_join" />
          </UitkHeading>
          <UitkText>
            <LocalizedText message="home.benefits.content.our_network_provides" />
          </UitkText>
          <UitkList className="Benefits__list" bullet="icon-alternate" spacing={false}>
            <UitkListItem key="listitem-1-1">
              <UitkIcon name="monetization_on" theme="default" />
              <div>
                <UitkSpacing padding={{ blockend: 'two' }}>
                  <UitkHeading tag="h3" size={5}>
                    <LocalizedText message="home.benefits.subheading.broadest_offerings" />
                  </UitkHeading>
                </UitkSpacing>
                <UitkText>
                  <LocalizedText message="home.benefits.content.inventory_unlock" />
                  <UitkList>
                    <UitkListItem>
                      <LocalizedText message="home.benefits.listitem.properties" />
                    </UitkListItem>
                    <UitkListItem>
                      <LocalizedText message="home.benefits.listitem.activities" />
                    </UitkListItem>
                    <UitkListItem>
                      <LocalizedText message="home.benefits.listitem.500airlines" />
                    </UitkListItem>
                    <UitkListItem>
                      <LocalizedText message="home.benefits.listitem.rentals" />
                    </UitkListItem>
                    <UitkListItem>
                      <LocalizedText message="home.benefits.listitem.cruise" />
                    </UitkListItem>
                  </UitkList>
                </UitkText>
              </div>
            </UitkListItem>
            <UitkListItem key="listitem-1-2">
              <UitkIcon name="apps" />
              <div>
                <UitkSpacing padding={{ blockend: 'two' }}>
                  <UitkHeading tag="h3" size={5}>
                    <LocalizedText message="home.benefits.subheading.monetize_traffic" />
                  </UitkHeading>
                </UitkSpacing>
                <UitkText>
                  <LocalizedText message="home.benefits.content.earn_commission" />
                </UitkText>
              </div>
            </UitkListItem>
            <UitkListItem key="listitem-1-3">
              <UitkIcon name="shopping_cart" />
              <div>
                <UitkSpacing padding={{ blockend: 'two' }}>
                  <UitkHeading tag="h3" size={5}>
                    <LocalizedText message="home.benefits.subheading.invest_technology" />
                  </UitkHeading>
                </UitkSpacing>
                <UitkText>
                  <LocalizedText message="home.benefits.content.products_expand_business" />
                </UitkText>
              </div>
            </UitkListItem>
          </UitkList>
        </UitkLayoutFlex>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
}

export { Benefits };
