// 3rd party imports
import * as React from 'react';

// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkSpacing } from 'uitk-react-spacing';

import { BrandItems } from './brand-cards-items';

function Brands() {
  return (
    <div className="Brands container">
      <UitkHeading tag="h2" size={3}>
        <LocalizedText message="home.brands.heading.access_to_brands" />
      </UitkHeading>
      <UitkSpacing padding={{ block: 'six' }}>
        <UitkLayoutFlex className="Brands__list" justifyContent="space-between" wrap="wrap" space="four">
          {BrandItems().map((item, i) => (
            <UitkLayoutFlexItem key={i}>
              <UitkFigure imageFit="contain" className="Brands__logo">
                <UitkImage alt={item.brand} src={item.logo} />
              </UitkFigure>
            </UitkLayoutFlexItem>
          ))}
        </UitkLayoutFlex>
      </UitkSpacing>
    </div>
  );
}

export { Brands };
