import { useLocalization } from '@shared-ui/localization-context';

function BrandItems() {
  const l10n = useLocalization();

  const brands = [
    {
      brand: 'Expedia',
      logo: 'https://www.expedia.com/_dms/header/logo.svg?siteid=1&locale=en_US&3ec60977',
      description: l10n.formatText('home.brand-items.expedia.description'),
      lobs: ['hotels', 'packages', 'flights', 'activities', 'cars', 'cruise'],
    },
    {
      brand: 'Hcom',
      logo: 'https://www.hotels.com/_dms/header/logo.svg?siteid=300000001&locale=en_US&3ec60977',
      description: l10n.formatText('home.brand-items.hcom.description'),
      lobs: ['hotels'],
    },
    {
      brand: 'Vrbo',
      logo: 'https://www.vrbo.com/_dms/header/logo.svg?siteid=9001001&locale=en_US&3ec60977',
      description: l10n.formatText('home.brand-items.vrbo.description'),
      lobs: ['vacation_rentals'],
    },
    {
      brand: 'Stayz',
      logo: 'https://www.stayz.com.au/_dms/header/logo.svg?siteid=9005044&locale=en_AU&3ec60977',
      description: l10n.formatText('home.brand-items.stayz.description'),
      lobs: ['vacation_rentals'],
    },
    {
      brand: 'Abritel',
      logo: 'https://www.abritel.fr/_dms/header/logo.svg?siteid=9003013&locale=fr_FR&3ec60977',
      description: l10n.formatText('home.brand-items.abritel.description'),
      lobs: ['vacation_rentals'],
    },
    {
      brand: 'FeWo-direkt',
      logo: 'https://www.fewo-direkt.de/_dms/header/logo.svg?siteid=9003020&locale=de_DE&3ec60977',
      description: l10n.formatText('home.brand-items.fewo-direkt.description'),
      lobs: ['vacation_rentals'],
    },
  ];

  return brands;
}

export { BrandItems };
