// 3rd party imports
import * as React from 'react';
import { FunctionComponent } from 'react';

// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';

// components
import { UitkButtonSize } from 'uitk-react-button';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';

import { JoinNowAction } from 'src/shared/join-now-action';

const ApplyNow: FunctionComponent = () => (
  <div className="ApplyNow container">
    <UitkLayoutFlex space="six" direction="column" alignItems="center">
      <UitkLayoutFlexItem>
        <UitkHeading tag="h1" size={4} align="center">
          <LocalizedText message="home.apply-now.heading.apply_now" />
        </UitkHeading>
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <UitkText className="ApplyNow__descritpion" align="center">
          <LocalizedText message="home.apply-now.content.reach_bigger_audience" />
        </UitkText>
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <JoinNowAction size={UitkButtonSize.LARGE} actionLocation="page_bottom" />
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  </div>
);

export { ApplyNow };
