// 3rd party imports
import * as React from 'react';
import { FunctionComponent } from 'react';

// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';

// ui
import { UitkLink } from 'uitk-react-link';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';

import { useStores } from 'src/hooks';
import { homeJoinNowSelectedEvent, makeLocaleParam } from 'src/util';

const Flows: FunctionComponent = () => {
  const track = useEgClickstream();
  const { context } = useStores();

  return (
    <div className="Flows container">
      <UitkSpacing padding={{ blockend: 'twelve' }}>
        <div>
          <UitkSpacing padding={{ blockend: 'four' }}>
            <UitkHeading tag="h2" size={3}>
              <LocalizedText message="home.flows.heading.how_our_program_works" />
            </UitkHeading>
          </UitkSpacing>
          <UitkText>
            <LocalizedText message="home.flows.content.use_tools_drive_traffic" />
          </UitkText>
        </div>
      </UitkSpacing>
      <UitkList type="ordered" orientation="inline" className="Flows__list">
        <UitkListItem>
          <UitkSpacing padding={{ blockend: 'two' }}>
            <UitkHeading tag="h3" size={4}>
              <LocalizedText message="home.flows.subheading.join" />
            </UitkHeading>
          </UitkSpacing>
          <UitkText size={300}>
            <LocalizedText message="home.flows.content.apply_to_join" />
          </UitkText>
          <UitkSpacing padding={{ blockstart: 'two' }}>
            <UitkLink onClickCapture={() => homeJoinNowSelectedEvent(track, 'link')}>
              <a href={`/${makeLocaleParam(context.locale)}/signup`}>
                <LocalizedText message="common.join_now" />
              </a>
            </UitkLink>
          </UitkSpacing>
        </UitkListItem>
        <UitkListItem>
          <UitkSpacing padding={{ blockend: 'two' }}>
            <UitkHeading tag="h3" size={4}>
              <LocalizedText message="home.flows.subheading.share" />
            </UitkHeading>
          </UitkSpacing>
          <UitkText size={300}>
            <LocalizedText message="home.flows.content.use_our_tools" />
          </UitkText>
        </UitkListItem>
        <UitkListItem>
          <UitkSpacing padding={{ blockend: 'two' }}>
            <UitkHeading tag="h3" size={4}>
              <LocalizedText message="home.flows.subheading.earn" />
            </UitkHeading>
          </UitkSpacing>
          <UitkText size={300}>
            <LocalizedText message="home.flows.content.earning" />
          </UitkText>
        </UitkListItem>
      </UitkList>
    </div>
  );
};

export { Flows };
