// 3rd party imports
import * as React from 'react';
import { FunctionComponent } from 'react';

// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkFigure } from 'uitk-react-images';
import { UitkButtonSize } from 'uitk-react-button';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';

// components
import { JoinNowAction } from 'src/shared/join-now-action';

const Hero: FunctionComponent = () => (
  <UitkFigure src="../images/home/hero.jpg" className="Hero">
    <UitkSpacing padding="six">
      <UitkLayoutFlex
        direction="column"
        className="Hero__contents container"
        justifyContent="center"
        space="four"
        blockSize="full_size"
      >
        <UitkLayoutFlexItem>
          <UitkHeading tag="h1" size={2}>
            <LocalizedText message="home.hero.heading.join_today" />
          </UitkHeading>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <UitkText size={400}>
            <LocalizedText message="home.hero.content.earning_commision" />
          </UitkText>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <UitkLayoutFlex direction="row" space="four">
            <UitkLayoutFlexItem>
              <JoinNowAction size={UitkButtonSize.LARGE} actionLocation="hero" />
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkSpacing>
  </UitkFigure>
);

export { Hero };
