// 3rd party imports
import * as React from 'react';

// ui
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkCarousel } from 'uitk-react-carousel';
import { UitkCard } from 'uitk-react-cards';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';

import { BrandItems } from './brand-cards-items';

function BrandCards() {
  const { useRef, useState, useEffect } = React;

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const l10n = useLocalization();

  const onIndexChange = (index) => {
    setCurrentIndex(index);
  };

  const numberOfCards = BrandItems().length;

  let cardIndex = 0;

  useEffect(() => {
    const timer = setTimeout(() => {
      cardIndex = currentIndex < numberOfCards - 3 ? currentIndex + 1 : 0;
      carouselRef.current.changeIndex(cardIndex);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  const brandCardsCarouselProps = {
    carouselName: 'BrandCardsCarousel',
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3,
    },
    pageBy: 1,
    peek: true,
    wrapItems: false,
    buttonText: {
      nextButton: l10n.formatText('common.next'),
      prevButton: l10n.formatText('common.previous'),
    },
    onIndexChange,
    navigationIconId: {
      next: 'next-button-external-control',
      prev: 'prev-button-external-control',
    },
    peekWidth: 20,
  };

  return (
    <div className="BrandCards container">
      <UitkSpacing padding={{ blockend: 'six' }}>
        <UitkHeading tag="h2" size={3}>
          <LocalizedText message="home.brand-cards.heading.grow_with_our_brands" />
        </UitkHeading>
      </UitkSpacing>
      <UitkCarousel {...brandCardsCarouselProps} carouselRef={carouselRef}>
        {BrandItems().map((item, i) => (
          <UitkCard key={i} tag="li" padded={true} border={true}>
            <UitkFigure imageFit="contain" className="Brands__logo">
              <UitkImage alt={item.brand} src={item.logo} />
            </UitkFigure>
            <UitkSpacing padding={{ block: 'four' }}>
              <UitkText size={300}>{item.description}</UitkText>
            </UitkSpacing>
            <UitkLayoutGrid columns={2} space="two">
              {item.lobs.map((lob, l) => {
                return (
                  <UitkLayoutGridItem key={l}>
                    <UitkText size={200} className="BrandCards__lob-item">
                      <UitkIcon name={`lob_${lob}`} />
                      <LocalizedText message={`programs.lobs.${lob}`} />
                    </UitkText>
                  </UitkLayoutGridItem>
                );
              })}
            </UitkLayoutGrid>
          </UitkCard>
        ))}
      </UitkCarousel>
    </div>
  );
}

export { BrandCards };
