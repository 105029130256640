// 3rd party imports
import * as React from 'react';
import { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';

// components
import { Newhome } from '../components/newhome';
import { Layout } from '../shared/layout';

// Utils
import { affiliateHubHomePresentedEvent } from 'src/util';

const HomePage = observer(
  // tslint:disable-next-line:no-shadowed-variable
  function HomePage() {
    const track = useEgClickstream();

    useEffect(() => {
      affiliateHubHomePresentedEvent(track);
    }, [track]);

    return (
      <Layout>
        <Newhome />
      </Layout>
    );
  },
);

export default HomePage;
