// 3rd party imports
import * as React from 'react';

// ui
import { UitkSpacing } from 'uitk-react-spacing';

// components
import { ApplyNow } from './apply-now';
import { Benefits } from './benefits';
import { Brands } from './brands';
import { BrandCards } from './brand-cards';
import { Flows } from './flows';
import { Hero } from './hero';
import { Products } from './products';

function Newhome() {
  return (
    <>
      <section>
        <Hero />
      </section>

      <UitkSpacing padding={{ block: 'twelve' }}>
        <section>
          <Brands />
        </section>
      </UitkSpacing>

      <UitkSpacing padding={{ block: 'sixteen' }} className="shaded-section-bg">
        <section>
          <Benefits />
        </section>
      </UitkSpacing>

      <UitkSpacing padding={{ block: 'twelve' }}>
        <section>
          <Flows />
        </section>
      </UitkSpacing>
      <UitkSpacing padding={{ block: 'twelve' }} className="shaded-section-bg">
        <section>
          <BrandCards />
        </section>
      </UitkSpacing>
      <UitkSpacing padding={{ block: 'twelve' }}>
        <section>
          <Products />
        </section>
      </UitkSpacing>
      <UitkSpacing padding={{ block: 'twelve' }} className="shaded-section-bg">
        <section>
          <ApplyNow />
        </section>
      </UitkSpacing>
    </>
  );
}

export { Newhome };
